//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysFileInfoUpload } from '@/api/modular/system/fileManage'
import { sysPersonalUpdateInfo, sysUserUpdateAvatar, userUpdateSignature } from '@/api/modular/system/userManage'
import CropUpload from '@/components/CropUpload'
import XnSignName from '@/components/XnSignName'
import store from '@/store'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import moment from 'moment'
import Vue from 'vue'
import { mapGetters } from 'vuex'
// mapActions
export default {
  components: {
    XnSignName,
    CropUpload,
    OpenData,
  },
  data() {
    return {
      // cropper
      preview: {},
      form: this.$form.createForm(this),
      avatarLoading: false,
      sexData: [],
      option: {
        img: '/avatar2.jpg',
        info: true,
        size: 1,
        outputType: 'jpeg',
        canScale: false,
        autoCrop: true,
        // 只有自動截圖開啟 寬度高度才生效
        autoCropWidth: 180,
        autoCropHeight: 180,
        fixedBox: true,
        // 開啟寬度和高度比例
        fixed: true,
        fixedNumber: [1, 1],
        // userInfo
        birthdayString: '',
      },
      layout: {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 12,
        },
      },
      userId: '',
    }
  },
  computed: {
    ...mapGetters(['avatar', 'userInfo']),
  },
  mounted() {
    this.initUserInfo()
    if (Vue.ls.get('WX_data_set')) {
      this.userId = Vue.ls.get('WX_data_set').userId
    }
  },
  methods: {
    // 頭像裁剪圖片回調
    cropUploadSuccess(data) {
      // 轉換為file類型
      const result = new File([data.blobData], data.fileName, { type: data.fileType, lastModified: Date.now() })
      const fileData = new FormData()
      fileData.append('file', result)
      this.avatarLoading = true
      sysFileInfoUpload(fileData).then((res) => {
        const { success, message } = res
        if (success) {
          this.updateAvatar(res.data)
        } else {
          this.avatarLoading = false
          this.$message.error(message)
        }
      })
    },
    // 修改頭像
    updateAvatar(data) {
      const params = {
        id: this.userInfo?.id,
        avatar: data,
      }
      sysUserUpdateAvatar(params).then((res) => {
        const { success, message } = res
        if (success) {
          this.userInfo.avatar = data
          // 更新緩存
          store.commit('SET_INFO', this.userInfo)
          store.commit('SET_AVATAR', process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + data)
          this.$message.success('頭像上傳修改成功')
        } else {
          this.$message.error(message)
        }
        this.avatarLoading = false
      })
    },
    // 簽名板組件回調
    signSuccess(value) {
      const param = {
        signature: value,
        id: this.userInfo.id,
      }
      userUpdateSignature(param)
        .then(() => {
          this.userInfo.signature = value
          // 更新緩存
          store.commit('SET_INFO', this.userInfo)
        })
        .catch((err) => {
          this.$message.error('提交報錯啦~~')
        })
    },
    // ...mapActions(['GetInfo']),
    /**
     * 初始化用戶信息
     */
    initUserInfo() {
      setTimeout(() => {
        this.form.setFieldsValue({
          account: this.userInfo.account,
          birthday: this.userInfo.birthday ? moment(this.userInfo.birthday, 'YYYY-MM-DD') : undefined,
          nickName: this.userInfo.nickName,
          name: this.userInfo.name,
          sex: this.userInfo.sex.toString(),
          email: this.userInfo.email,
          phone: this.userInfo.phone,
          tel: this.userInfo.tel,
        })
        this.birthdayString = moment(this.userInfo.birthday).format('YYYY-MM-DD')
        this.option.img = process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + this.userInfo.avatar
        this.getSexData()
      }, 100)
    },
    /**
     * 日期需單獨轉換
     */
    onChange(date, dateString) {
      this.birthdayString = dateString
    },
    submitUserInfo() {
      const {
        form: { validateFields },
      } = this
      validateFields((err, values) => {
        if (!err) {
          values.birthday = this.birthdayString
          values.id = this.userInfo.id
          sysPersonalUpdateInfo(values).then((res) => {
            if (res.success) {
              this.$message.success('個人信息更新成功')
              store.dispatch('GetInfo').then(() => {})
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    getSexData() {
      this.sexData = this.$options.filters['dictData']('sex')
    },
    setavatar(url) {
      this.option.img = process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + url
      store.dispatch('GetInfo').then(() => {})
    },
  },
}
